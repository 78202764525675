import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { ProductDetailsService } from '../../services/product-details-service';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { Router } from '@angular/router';
import { ProductService } from '@spartacus/core';
import { Options } from '../../models/product-info';

@Component({
  selector: 'app-product-introduction',
  templateUrl: './product-introduction.component.html',
  styleUrl: './product-introduction.component.scss'
})
export class ProductIntroductionComponent implements OnInit, OnDestroy {

  productDetails$: Observable<any>;
  private unsubscribe$ = new Subject<void>();

  constructor(
    public productsService: ProductDetailsService,
    private globalService: GlobalService,
    private router: Router,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.productDetails$ = this.productsService.getProduct();
    this.handleSubscriptions();
  }

  handleSubscriptions() {
    this.globalService.productDetail$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data) {
          // this.checkLegalEntityChange();
          window.location.reload();
        }
      });
  }

  /** To be considered later */
  checkLegalEntityChange() {
    let regexArr: RegExpMatchArray = this.router.url.match(new RegExp(/\/p\/[a-zA-Z0-9]+(?:_N|_R)?/, 'gi'));
    if (regexArr?.length > 0) {
      let productCode = regexArr?.at(0)?.split('/p/')?.at(1);
      this.productDetails$ = this.productService.get(productCode, 'FULL')
        .pipe(
          tap(response => console.log('uponlegalEntityChange:', response)),
          takeUntil(this.unsubscribe$)
        );
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}